<template>
	<div class="home" :class="{ dataLoading: dataLoading }">
		<div class="sidebar__controls-mobile" :class="{ 'ios-padding': isIOS }">
			<div class="price" v-show="step !== 4">
				PREIS: <strong>{{ price }}</strong>
			</div>
			<div class="buttons">
				<button
					class="button back"
					@click="mobileBack()"
					v-show="step !== 4"
					:disabled="!showMobileBackButton"
				>
					<svg>
						<use href="#icon_arrow-left" />
					</svg>
				</button>
				<button
					class="button"
					@click="mobileNext()"
					:class="{ last: step === 4 }"
					:disabled="(step == 4 && !terms) || isLoading || !hasSound"
				>
					<span v-show="step < 4 && !isLoading">WEITER</span>
					<span v-show="step == 4 && !isLoading && hasSound"
						>IN DEN WARENKORB <strong>({{ price }})</strong></span
					>
					<span v-show="isLoading">IN ERSTELLUNG...</span>
				</button>
			</div>
		</div>
		<!-- sidebar controls -->
		<transition name="fade">
			<Loader v-show="dataLoading" />
		</transition>
		<icons />
		<div class="layout">
			<div class="scene">
				<Poster
					ref="poster"
					:uid="uid"
					:icon="icon"
					:wavePeaks="wavePeaks"
					:posterSize="posterSize"
					:currStyle="currStyle"
					:curBorder="curBorder"
					:text="text_final"
					:pDimensions="p_dimensions"
					:link="link"
					:linkFinal="link_final"
					:key="posterIndex"
				/>
				<div style="display:none">
					<div id="waveform"></div>
				</div>
			</div>
			<div class="sidebar">
				<div class="editor__mobile-tabs">
					<button
						class="button"
						@click="step = 1"
						:class="{ active: step == 1 }"
					>
						Audio
					</button>
					<button
						class="button"
						@click="step = 2"
						:class="{ active: step == 2 }"
					>
						Stil
					</button>
					<button
						class="button"
						@click="step = 3"
						:class="{ active: step == 3 }"
					>
						Widmung
					</button>
					<button
						class="button"
						@click="step = 4"
						:class="{ active: step == 4 }"
					>
						Extras
					</button>
				</div>
				<!--  /mobile tabs -->
				<div class="sidebar__wizard">
					<div class="sidebar__header">
						<img src="@/assets/images/logo.png" alt="" />
					</div>

					<div
						class="editor__audio editor__section"
						:class="{ active: step == 1 }"
					>
						<div class="editor__section-title">
							<span>Audio</span>
						</div>
						<div class="editor__controls">
							<button
								class="editor__record editor__button"
								@click="toggleRecording"
								:class="{ recording: isRecording }"
								:disabled="isLoading || dataLoading"
							>
								<svg v-show="!isRecording">
									<use href="#icon_mic"></use>
								</svg>
								<svg v-show="isRecording">
									<use href="#icon_stop"></use>
								</svg>

								{{ b_text.record
								}}<span class="timer" v-show="isRecording">{{
									record_timer
								}}</span>
							</button>
							<div class="editor__error" style="display:none;">
								Microphone is disabled
							</div>
              <!--
              <div class="editor__divider">
								<span>oder</span>
							</div>
							<button
								class="editor__upload editor__button"
								@click="chooseFile()"
								::disabled="isLoading ||dataLoading || isRecording"
							>
								<svg>
									<use href="#icon_upload"></use>
								</svg>
								{{ b_text.upload }}
							</button>
							<input
								type="file"
								name=""
								style="display:none!important"
								id="fileUpload"
								@change="processFile"
							/>
							-->
						</div>
						<div class="editor__error" v-show="file_error.type">
							MEMOBILD kann nur Dateien im Audio-Format
							bearbeiten.
						</div>
						<div class="editor__error" v-show="file_error.size">
							Maximum filesize is {{ max_size }} mb
						</div>
						<div style="display:none!important"></div>
						<audio
							class="editor__playback"
							controls="controls"
							ref="audioPlayer"
							:src="blob_url ? blob_url : default_sound"
							type="audio/mp3"
							:key="player_index"
							:controlsList="
								!hasSound ? 'nodownload' : 'download'
							"
						/>
					</div>
					<!-- section -->
					<div
						class="editor__style-select editor__section"
						:class="{ active: step == 2 }"
					>
						<div
							class="editor__section-title"
							style="margin-top:15px;"
						>
							<span>Stil</span>
						</div>
						<div class="editor__little-title">
							Farbkombination
						</div>
						<div class="options">
							<button
								v-for="(style, i) in styles"
								:key="i"
								:disabled="isLoading || dataLoading"
								@click="setStyle(i)"
								:class="{ active: i == style_index }"
								:style="{
									backgroundColor: styles[i].background,
								}"
							>
								<iconWave :fill="styles[i].accent_color" />
							</button>
						</div>
					</div>
					<!-- section -->
					<div
						class="editor__audio editor__text editor__section"
						:class="{ active: step == 3 }"
						style="padding-bottom:0px;"
					>
						<div class="editor__section-title">
							<span>Widmung</span>
						</div>
						<div class="editor__error" v-show="text_error_emoji">
							Bitte benutze keine Emojis, da diese nicht richtig
							abgebildet werden können.
						</div>
						<div class="input__group text">
							<label for="text_input_1">Zitat</label>
							<input
								type="text"
								v-model="text.line_1"
								:maxlength="text_length.line_1"
								:disabled="isLoading || dataLoading"
								:placeholder="default_text.line_1"
							/>
							<div
								class="clear"
								v-show="text.line_1 && text.line_1.length"
								@click="text.line_1 = ''"
							>
								<svg>
									<use href="#icon_close"></use>
								</svg>
							</div>
						</div>
						<div class="input__group text">
							<label for="text_input_1">Name</label>
							<input
								type="text"
								v-model="text.line_2"
								placeholder="MEMOBILD"
								:maxlength="text_length.line_2"
								:disabled="isLoading || dataLoading"
							/>
							<div
								class="clear"
								v-show="text.line_2 && text.line_2.length"
								@click="text.line_2 = ''"
							>
								<svg>
									<use href="#icon_close"></use>
								</svg>
							</div>
						</div>
						<div class="input__group text">
							<label for="text_input_1">QR-text</label>
							<input
								type="text"
								v-model="text.under_qr"
								:placeholder="default_text.under_qr"
								:maxlength="text_length.under_qr"
								:disabled="isLoading || dataLoading"
							/>
							<div
								class="clear"
								v-show="text.under_qr && text.under_qr.length"
								@click="clearQrText()"
							>
								<svg>
									<use href="#icon_close"></use>
								</svg>
							</div>
						</div>
						<div class="input__group text">
							<label for="text_input_1">Symbol</label>
							<div class="styled-select">
								<select
									v-model="icon"
									autocapitalize="characters"
								>
									<option
										v-for="i in icons"
										:key="i"
										:value="i"
										>{{ i }}</option
									>
								</select>
								<span class="drop">
									<svg>
										<use href="#icon_dropdown"></use>
									</svg>
								</span>
							</div>
						</div>
					</div>
					<!-- section -->
					<div
						class="editor__audio editor__section"
						:class="{ active: step == 4 }"
					>
						<div class="editor__section-title">
							<span>Extras</span>
						</div>
						<div class="input__group">
							<div class="editor__little-title">
								Postergröße
							</div>
							<div class="editor__size-select">
								<div class="buttons">
									<button
										v-for="(s, i) in sizes"
										:key="s"
										@click="setSize(i)"
										:disabled="isLoading || dataLoading"
										:class="{ active: size_index == i }"
									>
										<span> {{ s }} cm </span>
									</button>
								</div>
							</div>
						</div>
						<div class="input__group">
							<div class="editor__little-title">
								BILDERRAHMEN
							</div>
							<div class="editor__frame-select">
								<div class="buttons">
									<button
										v-for="(b, i) in borders"
										:key="b"
										@click="setFrame(i)"
										:disabled="isLoading || dataLoading"
										:class="{ active: borders_index == i }"
									>
										<span v-if="b == 'Kein Rahmen'"
											>OHNE</span
										>
										<span v-if="b == 'Weißer Rahmen'"
											>WEISS</span
										>
										<span v-if="b == 'Schwarzer Rahmen'"
											>SCHWARZ</span
										>
									</button>
								</div>
							</div>
						</div>
						<div class="input__group">
							<div class="editor__terms">
								<label for="terms">
									<input
										type="checkbox"
										id="terms"
										v-model="terms"
									/>
									<span class="box">
										<svg>
											<use href="#icon_check"></use>
										</svg>
									</span>
								</label>
								<span class="label">
									Ich bin mit den
									<a
										target="_blank"
										href="https://www.memobild.de/pages/allgemeine-geschaftsbedingungen"
										>AGB</a
									>
									einverstanden und habe die
									<a
										href="https://www.memobild.de/pages/datenschutz"
										target="_blank"
										>Datenschutzerklärung</a
									>
									gelesen.
								</span>
							</div>
						</div>
					</div>
					<!-- section -->
				</div>
				<!-- wizard -->
				<div class="sidebar__controls">
					<div class="price">
						PREIS: <strong>{{ price }}</strong>
					</div>
					<div class="buttons">
						<a href="#" class="button back" style="display:none;"
							>Back</a
						>
						<button
							class="button"
							:disabled="!terms || isLoading || !hasSound"
							@click.prevent="proceedOrder()"
						>
							<span v-show="!isLoading">IN DEN WARENKORB</span>
							<span v-show="isLoading">IN ERSTELLUNG...</span>
						</button>
					</div>
				</div>
				<!-- sidebar controls -->
			</div>
			<!-- sidebar -->
		</div>
	</div>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import demoWave from "@/assets/data/demo_wave.json";
import icons from "@/components/icons.vue";
import iconWave from "@/components/iconWave.vue";
import AudioRecorder from "audio-recorder-polyfill";
import Poster from "@/components/poster.vue";
import Loader from "@/components/loader.vue";
import { isIOS } from "mobile-device-detect";

import { NormalDistribution } from "js-stats";

window.MediaRecorder = AudioRecorder;
export default {
	data() {
		return {
			isLoading: false,
			dataLoading: false,
			hasSound: false,
			is_uploaded: false,
			peaks: 512,
			uid: null,
			api_base:
				" https://europe-west3-memobild-editor.cloudfunctions.net/api",
			//api_base: "http://localhost:5001/memobild-editor/europe-west3/api",
			default_wave: demoWave,
			default_sound: require("@/assets/data/demo.wav"),
			default_text: {
				line_1: "Deine Stimme. Dein Geschenk.",
				line_2: " - MEMOBILD",
				under_qr: "Mit Liebe erstellt",
			},
			rawPeaks: [],
			wavePeaks: null,
			isIOS: isIOS,
			recorder: null,
			hasPermission: false,
			wavesurfer: null,
			link: "listen.memobild.de/_demo",
			link_final: null,
			blob_url: null,
			blob_data: null,
			file: null,
			record_timer: "00:00",
			timer: null,
			fileName: "",
			step: 1,
			player_index: 1,
			terms: false,
			case: "create",
			line: "",
			edit_poster: null,
			p_dimensions: {
				height: 0,
			},
			b_text: {
				record: "Aufnehmen",
				upload: "AUDIO HOCHLADEN",
			},
			isRecording: false,
			borders_index: 0,
			borders: ["Kein Rahmen", "Schwarzer Rahmen", "Weißer Rahmen"],
			size_index: 0,
			sizes: ["30x21", "40x30"],
			style_index: 0,
			styles: [
				{
					accent_color: "#000",
					background: "#fff",
				},
				{
					accent_color: "#fff",
					background: "#000",
				},
				{
					accent_color: "#D5C8A1",
					background: "#fff",
				},
			],
			text: {
				line_1: "",
				line_2: "",
				under_qr: "",
			},
			text_length: {
				line_1: 160,
				line_2: 28,
				under_qr: 32,
			},
			price_data: "",
			icons: [
				"kein symbol",
				"herz",
				"kreuz",
				"unendlichkeit",
				"crown",
				"musik",
			],
			icon: "herz",
			max_size: 20,
			awaitRender: false,
			posterIndex: 0,
			file_error: {
				size: false,
				type: false,
			},
		};
	},

	name: "Home",

	computed: {
		text_error_emoji: function() {
			var error = false;

			var found = JSON.stringify(this.text).match(
				/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
			);
			if (found) {
				error = true;
			}
			return error;
		},
		text_final: function() {
			var all;
			var line_1 = this.default_text.line_1;
			if (this.text.line_1) {
				line_1 = this.text.line_1.replace(
					/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
					""
				);
			}

			var line_2 = this.default_text.line_2;
			if (this.text.line_2) {
				line_2 =
					"- " +
					this.text.line_2.replace(
						/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
						""
					);
			}

			var under_qr = this.default_text.under_qr;
			if (this.text.under_qr) {
				under_qr = this.text.under_qr.replace(
					/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
					""
				);
			}

			all = {
				line_1: line_1,
				line_2: line_2,
				under_qr: under_qr,
			};
			return all;
		},
		posterSize: function() {
			var size = {};
			var selected_size = this.sizes[this.size_index];
			var sizes = selected_size.split("x");

			size = {
				w: sizes[0] * 10,
				h: sizes[1] * 10,
			};

			return size;
		},
		currStyle: function() {
			return this.styles[this.style_index];
		},
		curBorder: function() {
			return this.borders[this.borders_index];
		},
		price: function() {
			var baseprice = 0;
			if (this.price_data) {
				baseprice = this.price_data.base;
			}
			var price = 0;

			//Depending on size
			if (this.size_index == 1) {
				price = baseprice + this.price_data.size;
			}

			if (this.size_index == 0) {
				price = baseprice;
			}

			//Depending on frame
			if (this.borders_index > 0 && this.size_index == 0) {
				price = price + this.price_data.frame;
			}
			if (this.borders_index > 0 && this.size_index == 1) {
				price = price + this.price_data.frame_big;
			}

			price = price.toFixed(2);
			if (price.toString().indexOf(".") > -1) {
				price = price.toString().replace(".", ",");
			} else {
				price = price.toString() + ",00";
			}
			return price + " €";
		},
		showMobileBackButton: function() {
			var show = true;
			if (this.step === 1) {
				show = false;
			}
			return show;
		},
	},
	components: {
		iconWave,
		icons,
		Poster,
		Loader,
	},
	watch: {
		size_index: function() {
			this.peaksToRects(this.rawPeaks);
		},
	},
	methods: {
		clearQrText() {
			this.text.under_qr = "";
			// this.refreshPoster();
			this.$refs.poster.iconMargin = 0;
			this.$refs.poster.icon_key = Math.random();
			// alert('refreshed');
		},
		refreshPoster() {
			this.posterIndex = Math.random();
		},
		mobileBack() {
			if (this.step > 1) {
				this.step -= 1;
			}
		},
		mobileNext() {
			if (this.step == 4) {
				this.proceedOrder();
			}
			if (this.step < 4) {
				this.step += 1;
			}
		},
		onResize: function() {
			this.resizePoster();
		},
		refreshPlayer: function() {
			this.player_index = Math.random();
		},
		resizePoster: function() {
			var size = this.sizes[this.size_index];
			size = size.split("x");

			var width = size[0];
			var height = size[1];
			var poster_width = this.$refs.poster.clientWidth;
			if (width == "40") {
				poster_width = poster_width * 1.1;
			}
			var new_height = height * (poster_width / width);
			this.p_dimensions.height = new_height;
		},
		toggleRecording: function() {
			this.hasSound = false;
			this.isRecording = !this.isRecording;
			var recorded = 0;
			var seconds = () => {
				recorded += 1;
				if (recorded < 10) {
					this.record_timer = "00:0" + recorded;
				} else {
					if (recorded) {
						this.record_timer = "00:" + recorded;
					} else {
						this.record_timer = "00:00";
					}
				}

				if (recorded == 20) {
					this.isRecording = !this.isRecording;
					this.record_timer = "00:00";
					this.b_text.record = "Aufnehmen";
					this.recorder.stop();
					clearInterval(this.timer);
				}
			};

			if (this.isRecording) {
				this.b_text.record = "STOP";
				// Request permissions to record audio
				navigator.mediaDevices
					.getUserMedia({
						audio: true,
					})
					.then((stream) => {
						this.recorder = new MediaRecorder(stream);
						// Set record to <audio> when recording will be finished
						this.recorder.addEventListener("dataavailable", (e) => {
							//start timer
							this.blob_url = URL.createObjectURL(e.data);
							this.blob_data = e.data;
							this.hasSound = true;
							this.is_uploaded = false;
							this.createWaveSurfer(e.data);
						});
						// Start recording
						this.recorder.start();
						this.timer = setInterval(seconds, 1000);
					});
			}
			if (!this.isRecording) {
				// Stop recording
				this.recorder.stop();
				clearInterval(this.timer);
				this.record_timer = "00:00";
				// Remove “recording” icon from browser tab
				this.recorder.stream.getTracks().forEach((i) => i.stop());
				this.b_text.record = "Aufnehmen";
				this.refreshPlayer();
				// this.$refs.recorder.stop();
				//Stop timer
			}
		},
		setStyle(i) {
			this.style_index = i;
			this.wavesurfer.setWaveColor(this.currStyle.accent_color);
		},
		setSize(i) {
			this.size_index = i;
			this.resizePoster();
		},
		setFrame(i) {
			this.borders_index = i;
		},
		chooseFile() {
			document.getElementById("fileUpload").click();
		},
		processFile(event) {
			this.hasSound = false;
			this.file_error.type = false;
			if (this.wavesurfer !== null) this.wavesurfer.destroy();
			this.file = event.target.files[0];
			var type = event.target.files[0].type;
			var size = event.target.files[0].size;
			if (type.indexOf("audio") == -1) {
				this.file_error.type = true;
			}

			if (size > this.max_size * 1048576) {
				this.file_error.size = true;
			}
			if (!this.file_error.type && !this.file_error.size) {
				this.hasSound = true;
				this.createWaveSurfer(event.target.files[0]);
				this.blob_data = event.target.files[0];
				this.blob_url = window.URL.createObjectURL(
					event.target.files[0]
				);
				this.fileName = event.target.files[0].name;
				this.is_uploaded = true;
			}
		},

		createWaveSurfer(data, url) {
			this.wavesurfer = WaveSurfer.create({
				container: "#waveform",
				waveColor: this.currStyle.accent_color,
				barHeight: 320,
				barMinHeight: 10,
				barRadius: 3,
				fillParent: true,
				minPxPerSec: this.peaks,
				forceDecode: false,
				// normalize: true,
				interact: false,
				responsive: true,
				skipLength: 1,
				barGap: 0,

				drawingContextAttributes: {
					desynchronized: true,
				},
			});
			this.wavesurfer.on("ready", () => {
				// console.log("READY");
				var length =
					this.wavesurfer.getDuration() *
					this.wavesurfer.params.minPxPerSec *
					this.wavesurfer.params.pixelRatio;
				// var length = 600;
				var start = 0;
				var end = length;
				var rawPeaks = this.wavesurfer.backend.getPeaks(
					length,
					start,
					end
				);
				this.rawPeaks = rawPeaks;
				this.peaksToRects(rawPeaks);
				this.dataLoading = false;
			});
			if (url) {
				// console.log("URL");
				this.wavesurfer.load(url);
			}
			if (data) {
				// console.log("BLOB");
				this.wavesurfer.loadBlob(data);
			}
		},
		cutSilence(
			array,
			silenceThreshold = 0.2,
			targetSilencePercentages = [0.1, 0.1]
		) {
			let volumeStart = 0;
			let averagedArray = this.runningAverage(array);
			while (averagedArray[volumeStart] < silenceThreshold) {
				volumeStart++;
			}
			let volumeEnd = array.length - 1;
			while (averagedArray[volumeEnd] < silenceThreshold) {
				volumeEnd--;
			}
			const volumeLength = volumeEnd - volumeStart;
			let cutFrontUntil =
				volumeStart - volumeLength * targetSilencePercentages[0];
			let cutEndUntil =
				volumeEnd + volumeLength * targetSilencePercentages[1];
			cutFrontUntil = Math.max(0, cutFrontUntil);
			cutEndUntil = Math.min(array.length, cutEndUntil);
			const result = this.expandArray(
				array.slice(cutFrontUntil, cutEndUntil),
				array.length
			);
			return result;
		},
		logisticSigmoid(val) {
			if (val < -1 || val > 1)
				throw new Error("val must be between 0 and 1");
			return 1 / (1 + Math.pow(Math.E, -5 * val));
		},
		linearInterpolate(array, percentage) {
			const length = array.length - 1;
			const atZero = array[Math.floor(percentage * length)];
			const atOne = array[Math.ceil(percentage * length)]; // we translate the percentage from [0,1] to [-1,1]
			const val =
				(percentage * length - Math.floor(percentage * length)) * 2 - 1;
			const perc = this.logisticSigmoid(val);
			return atZero * perc + atOne * (1 - perc);
		},
		expandArray(array, length) {
			const result = [];
			for (let i = 0; i < length; i++) {
				result[i] = this.linearInterpolate(array, i / length);
			}
			return result;
		},
		runningAverage(array, windowLength = 0.05) {
			function fastSum(array, from, to) {
				let sum = 0;
				for (let i = from; i < to; i++) {
					sum += array[i];
				}
				return sum;
			}
			windowLength = Math.floor(windowLength * array.length);
			const halfLength = Math.floor(0.5 * windowLength);
			const result = [];
			let window = [];
			for (let i = 0; i < array.length; i++) {
				if (i + halfLength < array.length) {
					window.push(array[i + halfLength]);
				} else {
					window.push(0);
				}
				const sum = fastSum(
					array,
					Math.max(0, i - halfLength),
					Math.min(i + halfLength, array.length)
				);
				result.push(sum / windowLength);
			}
			return this.normalizeArray(result, 1, 0);
		},
		pow(array, power = 2) {
			return array.map((a) => Math.pow(a, power));
		},
		normalDistribute(original, strength = 1, silenceThreshold = 0) {
			const norm = new NormalDistribution(0, 1);
			const nonsilentOriginals = original.filter(
				(o) => o > silenceThreshold
			);
			if (nonsilentOriginals.length === 0)
				throw new Error("Recording is completely silent");
			const normalized = this.pow(
				this.normalizeArray(
					this.toPercentile(nonsilentOriginals).map((p) =>
						norm.invCumulativeProbability(p)
					)
				),
				4
			);
			const final = [];
			let silentIdOffset = 0;
			let i = 0;
			for (i = 0; i < normalized.length; i++) {
				while (original[silentIdOffset + i] <= silenceThreshold) {
					final.push(original[i + silentIdOffset++]);
				}
				final.push(
					strength * normalized[i] +
						original[silentIdOffset + i] * (1 - strength)
				);
			}
			while (original[silentIdOffset + i] <= silenceThreshold) {
				final.push(original[i + silentIdOffset++]);
			}
			if (final.length != original.length) {
				throw new Error("length changed!");
			}
			return final; //return original
		},
		normalizeArray(entries, newMax = 1, newMin = 0) {
			const min = entries.reduce((b, a) => Math.min(b, a));
			const max = entries.reduce((b, a) => Math.max(b, a));
			return entries.map(
				(e) => ((e - min) / (max - min)) * (newMax - newMin) + newMin
			);
		},

		getAverage(arr) {
			let sum = 0;

			for (let i = 0; i < arr.length; i++) {
				sum += arr[i];
			}
			return sum / arr.length;
		},

		//TRYING TO SMOOTH ARRAY
		smooth: function(values, alpha) {
			var weighted = this.average(values) * alpha;
			var smoothed = [];
			for (var i in values) {
				var curr = values[i];
				var prev = smoothed[i - 1] || values[values.length - 1];
				var next = curr || values[0];
				var improved = Number(
					this.average([weighted, prev, curr, next]).toFixed(2)
				);
				smoothed.push(improved);
			}
			return smoothed;
		},

		average: function(data) {
			var sum = data.reduce(function(sum, value) {
				return sum + value;
			}, 0);
			var avg = sum / data.length;
			return avg;
		},

		//END OF TRYING TO SMOOTH ARRAY

		interpolateArray(data, fitCount) {
			var linearInterpolate = function(before, after, atPoint) {
				return before + (after - before) * atPoint;
			};

			var newData = new Array();
			var springFactor = new Number((data.length - 1) / (fitCount - 1));
			newData[0] = data[0]; // for new allocation
			for (var i = 1; i < fitCount - 1; i++) {
				var tmp = i * springFactor;
				var before = new Number(Math.floor(tmp)).toFixed();
				var after = new Number(Math.ceil(tmp)).toFixed();
				var atPoint = tmp - before;
				newData[i] = linearInterpolate(
					data[before],
					data[after],
					atPoint
				);
			}
			newData[fitCount - 1] = data[data.length - 1]; // for new allocation
			return newData;
		},
		peaksToRects(input) {
			var numbers = input;
			var new_numbers = [];

			// var minMaxHeight = 80;

			//Find maximum value
			var max = Math.max.apply(Math, numbers),
				l = numbers.length,
				ind;
			var middle = numbers[Math.floor((numbers.length - 1) / 2)];
			console.log(max);

			//Set maximum not bigger than 100%
			var ratio = 1 / max;
			// console.log(max, ratio);

			for (ind = 0; ind < l; ind++) {
				var item_x = numbers[ind];
				if (numbers[ind] < 0) {
					item_x = 0;
				}
				if (item_x !== 0) {
					if (ind % 6 === 0) {
						if (item_x < middle) {
							item_x = item_x / 4;
						}
					}
					if (ind % 20 === 0) {
						if (item_x < middle) {
							item_x = item_x / 2;
						}
					}
					if (ind % 12 === 0) {
						if (item_x > middle) {
							item_x = item_x * 1.8;
						}
					}
					if (ind % 18 === 0) {
						if (item_x > middle) {
							item_x = item_x * 1.5;
						}
					}

					var final_item = item_x * ratio;
					if (final_item > 1.5) {
						final_item = 1.5;
					}
					new_numbers.push(final_item);
				}
			}
			const posterWidth = this.posterSize.w - 20;
			const posterHeight = this.posterSize.h;

			var data = this.cutSilence(new_numbers);
			data = this.interpolateArray(data, 1800);
			var rects = [];

			var peakWidth = posterWidth / data.length;

			var way = posterWidth - 10;
			var pekMultiplier = 42;
			if (this.size_index == 1) {
				pekMultiplier = 56;
			}

			pekMultiplier = pekMultiplier * 1.5;

			for (var i = 0; i < data.length; i++) {
				var height = Number(pekMultiplier * Math.abs(data[i]));

				if (height < 0.1) {
					height = 0.1;
				}

				var y = parseFloat(posterHeight / 2 - height / 2).toFixed(2);
				var item = {
					x: parseFloat(
						posterWidth - Number(way - peakWidth) + 2
					).toFixed(1),
					y: y,
					width: 1,
					height: parseFloat(height).toFixed(1),
				};
				way = way - peakWidth;
				rects.push(item);
			}
			this.wavePeaks = rects;
			// console.log("PEAKS");
		},
		uploadSound() {
			this.isLoading = true;
			var fd = new FormData();
			//Poster data
			if (this.is_uploaded) {
				fd.append("file", this.blob_data, this.fileName);
			} else {
				fd.append("file", this.blob_data, "sound.wav");
			}
			fd.append(
				"text_1",
				this.text.line_1.replace(
					/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
					""
				)
			);
			fd.append(
				"text_2",
				this.text.line_2.replace(
					/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
					""
				)
			);
			fd.append(
				"under_qr",
				this.text.under_qr.replace(
					/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
					""
				)
			);
			fd.append("size", this.sizes[this.size_index]);
			fd.append("frame", this.borders[this.borders_index]);

			//Service data
			var poster = document.getElementById("postersvg").outerHTML;
			poster = poster.replace(/<!--(.*?)-->/g, "");

			var qr_path = this.$refs.poster.qr_path;
			var qr_path_final = this.$refs.poster.qr_path_final;
			var final_svg = poster.replace(qr_path, qr_path_final);

			fd.append("svg", poster);

			fd.append("final_svg", final_svg);
			fd.append("uid", this.uid);
			fd.append("case", this.case);
			fd.append("icon", this.icon);
			fd.append("style_index", this.style_index);
			fd.append("size_index", this.size_index);
			fd.append("borders_index", this.borders_index);

			fetch(this.api_base + "/upload-sound/", {
				// Your POST endpoint
				method: "POST",
				body: fd,
			})
				.then((response) => {
					response.json();
				})
				.then((success) => {
					console.log(success);
					// product variant to edtit
					var line = "";
					if (this.case == "edit") {
						line = "&line=" + this.line;
					}
					// // // console.log(poster);
					window.location.href =
						"https://memobild.de/collections/frontpage/products/memobild?uid=" +
						this.uid +
						"&case=" +
						this.case +
						line;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getUid() {
			fetch(this.api_base + "/getUid/", {
				method: "GET",
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					// console.log(data);
					if (data.peaks) {
						this.peaks = data.peaks;
					}
					if (this.case != "edit") {
						this.uid = data.uid;
						this.link_final = "listen.memobild.de/" + this.uid;
					}

					this.posterIndex = Math.random();
					this.price_data = data.price;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getPosterToEdit() {
			this.dataLoading = true;
			fetch(this.api_base + "/getPoster/?uid=" + this.uid, {
				method: "GET",
			})
				.then((response) => {
					return response.json();
				})
				.then(async (data) => {
					// console.log(data);
					if (!data.error) {
						if (this.wavesurfer !== null) this.wavesurfer.destroy();
						this.edit_poster = data;
						var newVal = this.edit_poster;
						var url = newVal.sound_url;

						//Set text
						this.text.line_1 = newVal.text_1;
						this.text.line_2 = newVal.text_2;
						this.text.under_qr = newVal.under_qr;

						this.icon = newVal.icon;

						// set link
						this.link_final = "listen.memobild.de/" + this.uid;

						//Set sound
						this.blob_url = url;

						///Download existing sound to blob
						await fetch(url)
							.then((response) => response.blob())
							.then((blob) => {
								this.blob_data = blob;
								this.blob_url = window.URL.createObjectURL(
									blob
								);
								this.createWaveSurfer(blob);
							});
						//Set size and style
						this.setStyle(newVal.style_index);
						this.setFrame(newVal.borders_index);
						this.setSize(newVal.size_index);
						this.player_index += 1;
						this.refreshPoster();
						this.hasSound = true;
						this.dataLoading = false;
					} else {
						this.rawPeaks = this.default_wave.demo;
						this.peaksToRects(this.rawPeaks);
						this.getUid();
						this.refreshPoster();
						this.dataLoading = false;
						this.$router.push("/");
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async checkIfEdit() {
			if (this.$route.query.edit) {
				this.uid = this.$route.query.edit;
				this.case = "edit";
				this.line = this.$route.query.line;
				await this.getPosterToEdit();
				this.getUid();
			} else {
				this.getUid();
			}
		},
		async proceedOrder() {
			await this.uploadSound();
		},
	},
	mounted() {
		this.resizePoster();
		if (this.case == "create") {
			this.rawPeaks = this.default_wave.demo;
			this.peaksToRects(this.rawPeaks);
		}
		window.addEventListener("resize", () => {
			this.onResize();
		});
		//check if case = edit
		var WebFont = require("webfontloader");
		WebFont.load({
			google: {
				families: ["Poppins"],
			},
			active: function() {
				this.posterIndex = Math.random().toFixed(3);
			},
		});
	},
	async beforeMount() {
		await this.checkIfEdit();
	},
};
</script>

<style lang="scss">
$sidebar_width: 420px;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;700&display=swap");
@import "@/assets/scss/editor.scss";
@import "@/assets/scss/sidebar.scss";

body {
	padding: 0px;
	margin: 0px;
	font-family: "Poppins", sans-serif;
	height: 100% !important;
	min-height: 100% !important;
	max-height: 100% !important;
	overflow: hidden;
	position: fixed;
	width: 100%;
}
.poppins {
	font-family: "Poppins", sans-serif;
}
.home {
	transform: scale(1);

	@media (max-width: 1024px) {
		transform: none !important;
	}

	.sidebar {
		@media (min-width: 1024px) {
			transform: translateX(0px);
		}

		transition: 0.8s;
	}

	&.dataLoading {
		transform: scale(1.05);
		transition: 0.6s;

		.sidebar {
			transform: translateX(30px);
			transition: 0.8s;
		}
	}

	transition: 0.6s;
}

.layout {
	display: grid;
	grid-template-columns: calc(100vw - #{$sidebar_width}) $sidebar_width;
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	overflow: hidden;
	box-sizing: border-box;
	background: #eeeeee;

	@media (max-width: 768px) {
		display: flex;

		// display: block;
		flex-direction: column;
	}
}

.scene {
	margin: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;

	@media (max-width: 768px) {
		display: block;
		padding: 10px;
		margin: 0px;

		& > div {
			width: 90% !important;
			margin: 0px auto;
		}
	}
}

#poster {
	width: 1100px;
	//     height: 0px;
	max-width: 80%;
	min-width: 70%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: #fff;
	// box-shadow: 0px 10px 20px 0px rgba(#000, 0.1);
	transition: 0.3s;
	position: relative;
	box-sizing: border-box !important;
	border: 1px solid transparent;
	.text {
		position: absolute;
		left: 0;
		top: 20px;
		width: 100%;
		text-align: center;
	}
	@media (min-width: 768px) {
		transform: scale(1);
	}
	@media (max-width: 768px) {
		max-width: 100%;
		min-width: 100%;
	}
	&.big {
		@media (min-width: 768px) {
			transform: scale(1.1);
		}
	}
	.qr_holder {
		position: absolute;
		bottom: 30px;
		text-align: center;
		max-height: 30%;

		@media (max-width: 600px) {
			svg {
				width: 32px !important;
				height: 32px !important;
			}
		}

		.qr_text {
			font-size: 12px;
			margin-top: 10px;
		}
	}
}

#waveform {
	width: 100%;
	position: absolute;
	top: 0%;
	transform: translateY(0%);
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.6s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */
 {
	opacity: 0;
}

[ripple] .ripple--container .ripple--body {
	opacity: 0.5 !important;
	background-color: rgba(#fff, 0.1) !important;
	-webkit-animation: rippler 1000ms;
	animation: rippler 1000ms;
}
</style>
