<template>
<div class="loader-wave-container">
    <div class="loader-wave">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loader-wave-container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
}

.loader-wave {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    height: 80px;
    background: #000;
    padding: 0px 15px;
    box-sizing: border-box;
}

.loader-wave div {
    display: inline-block;
    width: 4px;
    background: #fff;
    animation: loader-wave 1.2s linear infinite;
}

.loader-wave div:nth-child(1) {
    animation-delay: -0.24s;
    height: 40px;
}

.loader-wave div:nth-child(2) {
    animation-delay: -0.12s;
    height: 26px;
}

.loader-wave div:nth-child(3) {
    animation-delay: -0.24s;
    height: 40px;
}

.loader-wave div:nth-child(4) {
    animation-delay: -0.12s;
    height: 40px;
}

.loader-wave div:nth-child(5) {
    animation-delay: -0.24s;
    height: 26px;
}

.loader-wave div:nth-child(6) {
    animation-delay: -0.12s;
    height: 40px;
}

.loader-wave div:nth-child(7) {
    animation-delay: -0.24s;
    height: 33px;
    margin-top: 3px;
}

@keyframes loader-wave {
    0% {
        transform: scaleY(1);
    }

    30% {
        transform: scaleY(0.8);
    }

    60% {
        transform: scaleY(1.3);
    }

    100% {
        transform: scaleY(1);
    }
}
</style>
