var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g',[(_vm.selectedIcon == 'unendlichkeit')?_c('svg',{attrs:{"version":"1.1","id":"icon_loop","height":1.8 * 3 * _vm.icon_multiplier,"width":3.3 * 3 * _vm.icon_multiplier,"viewBox":"0 0 250 415","xml:space":"preserve","x":_vm.text_w_qr == 0
					? _vm.posterSize.w / 2 - (1.5 * _vm.posterSize.w) / 100 - 2
					: _vm.posterSize.w / 2 + _vm.text_w_qr / 2 - 4,"y":_vm.spacing - 3.6 * _vm.icon_multiplier}},[_c('g',{staticClass:"st0"},[_c('path',{staticClass:"st1",attrs:{"d":"M386.8,381.2c69,0,125.2-56.1,125.2-125.2s-56.1-125.2-125.2-125.2c-29.4,0-64.6,17.2-107.6,52.6\n\t\tc-0.2,0.2-0.4,0.3-0.6,0.5l51.9,52.1c39.7-31.7,55.7-32.1,56.3-32.1c28.7,0,52,23.3,52,52s-23.3,52-52,52\n\t\tc-0.7,0-17.9-0.4-61.1-35.9c-5-4.1-85.9-82.7-93-88.6c-43-35.4-78.2-52.6-107.6-52.6C56.1,130.8,0,187,0,256\n\t\ts56.2,125.2,125.2,125.2c29.4,0,64.6-17.2,107.6-52.6c0.2-0.2,0.4-0.3,0.6-0.5l-51.7-52.2c-39.7,31.8-55.8,32.2-56.5,32.2\n\t\tc-28.7,0-52-23.3-52-52s23.3-52,52-52c0.7,0,19.8-1.9,60.5,35.5c20.8,19.1,88.4,85.1,93.6,89C323.4,362.5,357.4,381.2,386.8,381.2\n\t\tL386.8,381.2z","fill":"#fff"}})]),_c('g',[_c('g',[_c('path',{attrs:{"d":"M403.5,133.8c-67.2,0-122.9,60.1-147.5,91.6c-24.6-31.5-80.2-91.6-147.4-91.6c-59.2,0-107.3,54.4-107.3,121.3\n\t\t\ts48.1,121.3,107.3,121.3c67.2,0,122.9-60.1,147.5-91.6c24.6,31.5,80.2,91.6,147.5,91.6c59.2,0,107.3-54.4,107.3-121.3\n\t\t\tS462.6,133.8,403.5,133.8z M178.7,279.2c-26.2,25.2-47.6,30.5-61,30.5c-26.6,0-48.3-24.5-48.3-54.6s21.7-54.6,48.3-54.6\n\t\t\tc37.2,0,70,38.3,82.2,54.6C195.4,261.1,188,270.2,178.7,279.2z M394.4,309.7c-13.4,0-34.8-5.3-61-30.5c-9.4-9-16.7-18.1-21.2-24.1\n\t\t\tc12.2-16.3,45-54.6,82.2-54.6c26.6,0,48.3,24.5,48.3,54.6C442.6,285.2,421,309.7,394.4,309.7z","fill":"#fff"}})])])]):_vm._e(),(_vm.selectedIcon == 'herz')?_c('svg',{attrs:{"version":"1.1","id":"icon_heart","height":2.2 * 3 * _vm.icon_multiplier,"width":1.35 * 3 * _vm.icon_multiplier,"viewBox":"0 0 437.775 437.774","xml:space":"preserve","x":_vm.text_w_qr == 0
					? _vm.posterSize.w / 2 - (0.65 * _vm.posterSize.w) / 100
					: _vm.posterSize.w / 2 + _vm.text_w_qr / 2 + 1.7 - 3,"y":_vm.spacing - 3.8 * _vm.icon_multiplier + 0.3}},[_c('g',[_c('path',{attrs:{"fill":"#fff","d":"M316.722,29.761c66.852,0,121.053,54.202,121.053,121.041c0,110.478-218.893,257.212-218.893,257.212S0,266.569,0,150.801 C0,67.584,54.202,29.761,121.041,29.761c40.262,0,75.827,19.745,97.841,49.976C240.899,49.506,276.47,29.761,316.722,29.761z"}})])]):_vm._e(),(_vm.selectedIcon == 'musik')?_c('svg',{attrs:{"version":"1.1","id":"icon_music","height":1.6 * 3 * _vm.icon_multiplier,"width":3 * 3 * _vm.icon_multiplier,"viewBox":"0 0 500 550","xml:space":"preserve","x":_vm.text_w_qr == 0
					? _vm.posterSize.w / 2 - (1.5 * _vm.posterSize.w) / 100
					: _vm.posterSize.w / 2 + _vm.text_w_qr / 2 - 3.8,"y":_vm.spacing - 2.5 * _vm.icon_multiplier}},[_c('g',[_c('path',{attrs:{"fill":"#fff","d":"M474.29,24.97c-3.44-3.04-8-4.5-12.57-4.03L170.63,53.29c-8.19,0.91-14.86-0.37-14.39,16.07v288.19\n\nc-14.66-8.77-31.43-13.36-48.51-13.28c-44.58,0-80.86,29.01-80.86,64.69s36.27,64.69,80.86,64.69s80.86-29,80.86-64.69V164.78\n\nl258.74-28.82v189.21c-14.66-8.75-31.44-13.33-48.51-13.23c-44.58,0-80.86,29.01-80.86,64.69s36.27,64.69,80.86,64.69\n\ns80.86-29,80.86-64.69V37.02C479.67,32.42,477.71,28.04,474.29,24.97z"}})])]):_vm._e(),(_vm.selectedIcon == 'kreuz')?_c('svg',{attrs:{"version":"1.1","id":"icon_kreuz","height":1.6 * 3 * _vm.icon_multiplier,"width":1.6 * 3 * _vm.icon_multiplier,"viewBox":"0 0 450 450","xml:space":"preserve","x":_vm.text_w_qr == 0
					? _vm.posterSize.w / 2 - (1 * _vm.posterSize.w) / 100
					: _vm.posterSize.w / 2 + _vm.text_w_qr / 2 - 2,"y":_vm.spacing - 2.3}},[_c('g',[_c('path',{attrs:{"fill":"#fff","d":"M293,481.89V214.33h120v-80H293V26.78h-80v107.56H93v80h120v267.56H293z"}})])]):_vm._e(),(_vm.selectedIcon == 'crown')?_c('svg',{attrs:{"version":"1.1","id":"icon_crowj","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","height":1.8 * 3 * _vm.icon_multiplier,"width":2.2 * 3 * _vm.icon_multiplier,"viewBox":"0 0 512 512","xml:space":"preserve","x":_vm.text_w_qr == 0
					? _vm.posterSize.w / 2 - (1 * _vm.posterSize.w) / 100
					: _vm.posterSize.w / 2 + _vm.text_w_qr / 2 + 1.1 - 2.8,"y":_vm.spacing - 4 * _vm.icon_multiplier + 0.3}},[_c('path',{attrs:{"d":"M52.8,367.7h406.5l43.9-197.8c2.7-12.1-5-24.2-17.1-26.9c-6-1.3-12.3-0.2-17.4,3.2l-113.9,75.9L276.1,64.9\n\tc-5.6-11.1-19.1-15.6-30.2-10.1c-4.4,2.2-7.9,5.7-10.1,10.1l-78.7,157.3L43.3,146.3c-10.3-6.9-24.3-4.1-31.2,6.2\n\tc-3.4,5.1-4.6,11.4-3.2,17.4L52.8,367.7z","fill":"#fff"}}),_c('path',{attrs:{"d":"M53.3,390.2v45c0,12.4,10.1,22.5,22.5,22.5h360.3c12.4,0,22.5-10.1,22.5-22.5v-45H53.3z","fill":"#fff"}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }