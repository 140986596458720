var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.borderClass, { big: _vm.posterSize.w === 400 }],attrs:{"id":"poster"}},[_c('svg',{attrs:{"version":"1.1","id":"postersvg","baseProfile":"full","viewBox":'0 0 ' + _vm.posterSize.w + ' ' + _vm.posterSize.h,"xmlns":"http://www.w3.org/2000/svg"}},[_c('defs',[_c('linearGradient',{attrs:{"id":'gradient_' + _vm.uid,"x1":"25%","y1":"25%","x2":"75%","y2":"75%"}},[_c('stop',{attrs:{"offset":"0%","stop-color":_vm.currStyle.accent_color,"stop-opacity":"1"}}),_c('stop',{attrs:{"offset":"50%","stop-color":_vm.currStyle.accent_color,"stop-opacity":"1"}}),_c('stop',{attrs:{"offset":"100%","stop-color":_vm.currStyle.accent_color,"stop-opacity":"1"}})],1),_c('mask',{attrs:{"id":'maskGradient_' + _vm.uid}},[_c('g',[_c('rect',{attrs:{"height":"0.5","width":_vm.posterSize.w,"x":"0","y":_vm.posterSize.h / 2 - 0.5,"fill":"white"}})]),_c('g',{attrs:{"id":"soundwave"}},_vm._l((_vm.wavePeaks),function(t,i){return _c('rect',{key:i,attrs:{"x":t.x,"y":t.y,"height":t.height,"width":"0.4666666","stroke":"none","fill":"white","rx":"0","ry":"0"}})}),0),_c('text',{staticClass:"poppins",staticStyle:{"font-weight":"400"},attrs:{"x":_vm.posterSize.w / 2,"y":_vm.toptext_spacing,"font-size":(6 * _vm.posterSize.w) / 300,"text-anchor":"middle","font-family":"Poppins","font-style":"italic","fill":"white","width":"100"}},[_vm._l((_vm.upperText),function(line,i){return _c('tspan',{key:line,attrs:{"dy":i <= 1 ? i + 0.3 + 'em' : 1.3 + 'em',"x":_vm.posterSize.w / 2}},[_vm._v(" "+_vm._s(line)+" ")])}),(_vm.linesCount == 2 && _vm.upperText[1].length > 40)?_c('tspan',{attrs:{"dy":"1.3em","x":_vm.posterSize.w / 2,"fill":"transparent"}},[_vm._v(" 1 ")]):_vm._e(),_c('tspan',{attrs:{"font-weight":"bold","font-style":"normal","font-family":"Poppins"}},[_vm._v(" "+_vm._s(_vm.text.line_2)+" ")])],2),_c('g',{attrs:{"transform":'translate(' +
							Number(
								_vm.posterSize.w / 2 -
									14.5 * ((0.75 * _vm.posterSize.w) / 300)
							) +
							',' +
							Number(_vm.qr_spacing) +
							'), scale(' +
							Number((0.75 * _vm.posterSize.w) / 300) +
							')'}},[_c('path',{key:_vm.qr_key,attrs:{"id":"qr_code","d":_vm.qr_path,"width":"42","height":"42","fill":"white"}})]),_c('g',[_c('text',{attrs:{"id":"under_qr_text","x":Number(
								_vm.posterSize.w / 2 -
									_vm.iconMargin * (_vm.posterSize.w / 300)
							),"y":_vm.qr_text_spacing,"font-size":(3.5 * _vm.posterSize.w) / 300,"dominant-baseline":"middle","text-anchor":"middle","fill":"white","font-style":"normal","font-family":"Poppins"}},[_vm._v(" "+_vm._s(_vm.text.under_qr)+" ")])]),_c('svgIcons',{key:_vm.icon_key,ref:"svgIcons",attrs:{"selectedIcon":_vm.icon,"posterSize":_vm.posterSize,"text":_vm.underQrText,"spacing":_vm.qr_text_spacing}})],1)],1),_c('rect',{attrs:{"width":_vm.posterSize.w,"height":_vm.posterSize.h,"x":"0","y":"0","fill":_vm.currStyle.background}}),_c('rect',{attrs:{"width":_vm.posterSize.w,"height":_vm.posterSize.h,"x":"0","y":"0","mask":'url(#maskGradient_' + _vm.uid + ')',"fill":'url(#gradient_' + _vm.uid + ')'}})]),(_vm.link)?_c('qrcode-vue',{key:_vm.index + 2,ref:"qrcode",staticStyle:{"display":"none!important"},attrs:{"value":_vm.link,"foreground":_vm.currStyle.accent_color,"background":_vm.currStyle.background,"renderAs":"svg","size":"42","level":"H"}}):_vm._e(),(_vm.linkFinal)?_c('qrcode-vue',{key:_vm.index + 1,ref:"qrcode_final",staticStyle:{"display":"none!important"},attrs:{"value":_vm.linkFinal,"foreground":_vm.currStyle.accent_color,"background":_vm.currStyle.background,"renderAs":"svg","size":"42","level":"H"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }