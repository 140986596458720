<template>
<div>
    <svg width="86" height="53" viewBox="0 0 86 53" xmlns="http://www.w3.org/2000/svg">
        <rect width="6" height="53" rx="3" :fill="fill" />
        <rect x="14" y="11" width="6" height="31" rx="3" :fill="fill" />
        <rect x="27" width="6" height="53" rx="3" :fill="fill" />
        <rect x="41" width="6" height="53" rx="3" :fill="fill" />
        <rect x="54" y="10" width="6" height="34" rx="3" :fill="fill" />
        <rect x="68" width="6" height="53" rx="3" :fill="fill" />
        <rect x="80" y="8" width="6" height="41" rx="3" :fill="fill" />
    </svg>
</div>
</template>

<script>
export default {
    props: {
        fill: {
            type: String,
            default: "#000",
        },
    },
};
</script>
